



import { Component, Vue } from 'vue-property-decorator';
import store from '../../store';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';

@Component({
  components: {
    Breadcrumb,
  },
})
export default class AboutFlow extends Vue {
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: '入会から講座受講までの流れ',
      link: '',
    },
  ];
  get notFound() {
    return this.$store.getters['window/isLogin'];
  }
}
